var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home no-padding-top"},[_c('Loading',{attrs:{"loading":_vm.load}}),_c('TopIndigator',{attrs:{"viewIndex":_vm.viewIndex}}),_c('div',{staticClass:"section",attrs:{"id":"section1"}},[_c('Section1')],1),_c('div',{staticClass:"section",attrs:{"id":"section2"}},[_c('VideoSection',{attrs:{"playBtn":require('@/projects/sdj1/s2/youtube.png'),"title":"系列影片","close":require('@/projects/jh/s4/close.png'),"arrows":[require('@/projects/gydy/arrow-left.png'), require('@/projects/gydy/arrow-right.png')],"slideList":[
      {
        title: '萬眾矚目北高雄<br />百貨版圖新時代',
        img: require('@/projects/sdj1/s2/1.jpg'),
        video: 'https://www.youtube.com/embed/8sbUAwaPYJY',
        isPlay: false,
      },
      {
        title: '妻子嚴選高坪效<br />聽老婆的就對了',
        img: require('@/projects/sdj1/s2/2.jpg'),
        video: 'https://www.youtube.com/embed/-LFr3WaougI',
        isPlay: false,
      },
      {
        title: '在熟悉的環境裡<br />給父母親最好的',
        img: require('@/projects/sdj1/s2/3.jpg'),
        video: 'https://www.youtube.com/embed/uoQ8ZL-HNXQ',
        isPlay: false,
      } ]}})],1),_c('div',{staticClass:"section",attrs:{"id":"section4"}},[_c('Section4')],1),_c('div',{staticClass:"section",attrs:{"id":"section3"}},[_c('Section3')],1),_c('div',{staticClass:"section",attrs:{"id":"section5"}},[_c('Section5')],1),_c('div',{staticClass:"section",attrs:{"id":"section7"}},[_c('Section7')],1),_c('div',{staticClass:"section",attrs:{"id":"section6"}},[_c('Section6')],1),_c('div',{staticClass:"section",attrs:{"id":"section8"}},[_c('Section8')],1),_c('ContactSection',{staticClass:"section",attrs:{"id":"contact"}}),_c('MobileNav')],1)}
var staticRenderFns = []

export { render, staticRenderFns }