export default {
  address: '高雄市三民區明誠一路491號隔壁',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1840.9175873705049!2d120.32020565882075!3d22.65993322638741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e05cbf12b1aad%3A0x36423055d973658!2z6YGU6bqX5LiK5p2x5LqsIOaOpeW-heS4reW_gw!5e0!3m2!1szh-TW!2stw!4v1596098033334!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/PNJvdSdoPWvW5xVs5',
  phone: '07-3925188',
  fbLink: 'https://www.facebook.com/dalitokyo888/',
  fbMessage: 'https://m.me/dalitokyo888/',
  caseName: '達麗上東京',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '達麗建設事業股份有限公司'],
    ['責任施工', '寶信營造股份有限公司'],
    ['建築設計', '原東聯合建築師事務所'],
    ['棟戶規劃', '3棟，320戶住家，4戶店面'],
    ['基地面積', '1334坪'],
    ['樓層規劃', '地上24層，地下3層'],
  ],

  gtmCode: ['NTKG2VP'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  } 
} 